import type { NextPage } from "next";
import dynamic from "next/dynamic";

const NotFoundPage = dynamic(() => import("@mcp/core/dist/errors/404"), {
  ssr: false,
});

const Home: NextPage = () => <NotFoundPage />;

export default Home;
